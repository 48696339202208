import { template as template_7dbd773599b94dfeb3107dd6bd4e9d96 } from "@ember/template-compiler";
const FKLabel = template_7dbd773599b94dfeb3107dd6bd4e9d96(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
