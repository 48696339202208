import { template as template_bc0b4678b69e4581aa9d3c7aec961344 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_bc0b4678b69e4581aa9d3c7aec961344(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
