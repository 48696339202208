import { template as template_70ea84617dcb45968d6e437f34d47d60 } from "@ember/template-compiler";
const FKText = template_70ea84617dcb45968d6e437f34d47d60(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
