import { template as template_2812c841fc1541e2bb1b660153e43f53 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
import icon from "discourse-common/helpers/d-icon";
import { i18n } from "discourse-i18n";
const BulkSelectCell = template_2812c841fc1541e2bb1b660153e43f53(`
  <th class="bulk-select topic-list-data">
    {{#if @canBulkSelect}}
      <button
        {{on "click" @bulkSelectHelper.toggleBulkSelect}}
        title={{i18n "topics.bulk.toggle"}}
        class="btn-flat bulk-select"
      >
        {{icon "list-check"}}
      </button>
    {{/if}}
  </th>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
